<template>
    <div id="secteurs" class="page-wrapper page-secteurs">
        <!-- Hero -->
        <SmallHero v-if="contentIsLoaded" :hero="content.hero" />

        <!-- Intro Desc -->
        <section class="intro-desc site-max-width" v-if="contentIsLoaded && content.introDescription1.titre && content.introDescription1.texte">
            <article class="content" v-if="contentIsLoaded">
                <h2 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.introDescription1.surtitre }}</h2>
                <h3 class="subtitle medium-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.introDescription1.titre"></span></h3>
                <router-link :to="content.introDescription1.boutonUrl[$route.meta.lang]" class="site-btn" :title="content.introDescription1.bouton.customText" data-inview="fadeInUp" data-delay="400">{{ content.introDescription1.bouton.customText }}</router-link>
            </article>
            <div class="desc" data-inview="fadeInUp" data-delay="400">
                <p class="small-text">
                    <span v-if="contentIsLoaded" v-html="content.introDescription1.texte"></span>
                </p>
            </div>
        </section>
        <div v-else class="spacer">&nbsp;</div>

        <!-- Showcase -->
        <section class="showcase site-max-width" v-if="contentIsLoaded">
            <div class="grid-items col-2">
                <div class="item" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.gridImages1" :key="'sk2-' + i">
                    <img :src="item.image.url" :alt="item.image.titre">
                </div>
            </div>
        </section>

        <div class="simple-spacer"></div>

        <!-- Mission -->
        <Mission v-if="pageIsLoaded" id="mission" :introBlock="globals.footer.introBlock1" :gridIcones="globals.footer.gridIcones" />

        <!-- Autres Expertises -->
        <section id="autres-expertises" class="autres-expertises site-max-width" v-if="pageIsLoaded">
            <div class="intro-titles">
                <IntroTitles :block="content.introBlock2" />
                <div class="right">
                    <div class="flickity-btns">
                        <button class="prev" @click.prevent="expertiseGoTo('prev')">
                            <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-1" y="1" width="58" height="58" transform="matrix(-1 0 0 1 58 0)" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                                <path d="M29.2994 23.6724L24.165 28.8057L41 28.8057L41 31.1944L24.165 31.1944L29.2994 36.3277L27.6271 38L19.6283 30L27.6272 22L29.2994 23.6724Z" fill="#53585F"/>
                            </svg>
                        </button>
                        <button class="next" @click.prevent="expertiseGoTo('next')">
                            <svg height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="58" height="58" fill="white" stroke="#E8E8E8" stroke-width="2"/>
                                <path d="M30.7006 23.6724L35.835 28.8057L19 28.8057L19 31.1944L35.835 31.1944L30.7006 36.3277L32.3729 38L40.3717 30L32.3728 22L30.7006 23.6724Z" fill="#53585F"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="grid-items col-4 expertises-carousel" data-inview="fadeInUp" data-delay="300" v-if="pageIsLoaded">
                <router-link
                    :to="item.jsonUrl"
                    class="item carousel-cell"
                    title=""
                    v-for="(item, i) in content.expertisesProduits"
                    :key="i"
                >
                    <div class="img-wrap-hover">
                        <img :src="item.hero.image.url" :alt="item.hero.image.titre">
                    </div>
                    <h2 class="item-title sup-title">
                        <span>{{ item.title }}</span>
                        <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#53585F"/>
                        </svg>
                    </h2>
                </router-link>
            </div>
        </section>

        <!-- Secteurs -->
        <section id="secteurs" class="secteurs site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock3" />
            <div class="grid-labels is-bigger" data-inview="fadeInUp" data-delay="600">
                <router-link :to="item.jsonUrl" class="label" :title="item.title" v-for="(item, i) in autresSecteurs" :key="'se-' + i">
                    <h3>
                        <span>{{ item.title }}</span>
                        <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#59BBEB"/>
                        </svg>
                    </h3>
                </router-link>
            </div>
        </section>

        <div class="page-ender"></div>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

import Flickity from 'flickity'
import GLightbox from 'glightbox'

// import SvgLivraison from '@/components/svg/SvgLivraison.vue'
import SmallHero from '@/components/SmallHero.vue'
import IntroTitles from '@/components/IntroTitles.vue'
import Mission from '@/components/Mission.vue'

export default {
    name: 'Secteur',

    components: {
        // SvgLivraison,
        SmallHero,
        IntroTitles,
        Mission,
    },

    data() {
        return {
            expertisesCarousel: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    GLightbox()

                    setTimeout(() => {
                        this.expertisesCarousel = new Flickity(document.querySelector('.expertises-carousel'), {
                            cellAlign: 'left',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            pageDots: false,
                        })

                        initInViewAnimations()

                        setTimeout(() => {
                            this.expertisesCarousel.resize()
                        }, 2555);
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    mounted () {

    },

    computed: {
        autresSecteurs() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.secteursEntryShort.data.filter(i => i.jsonUrl !== this.content.jsonUrl )
        },
        autresExpertises() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.expertisesEntryShort.data
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        expertiseGoTo(direction) {
            if (direction === 'prev') {
                this.expertisesCarousel.previous()
                return
            }
            this.expertisesCarousel.next()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .spacer {
        position: relative;
        height: 6.5vw;
    }
</style>
